<template>
    <div class="view manufacturers" data-aos="fade-in">
        <div class="container-fluid">
            <!-- Title -->
            <div class="title" data-aos="fade-in">
                <!-- <div class="bar bar-left"></div> -->
                <div class="name">
                    <img src="@/assets/images/slot.png" alt=""> SLOT GROUPS <img src="@/assets/images/slot.png" alt="">
                </div>
                <!-- <div class="bar bar-right"></div> -->
            </div>
            <!-- Manufacturers -->
            <div class="row manufacturers  justify-content-center">
                <div class="col-xxl-4 col-xl-3 col-lg-4 col-sm-4 col-12" v-for="item in data" :key="item.id" >
                    <router-link class="no-link" :to="'/platform/manufacturers/' + item.GroupId + '?type=' + item.GroupType">
                        <div class="manufacturer" :style="[{ border: '3px solid' + item.Color }]">
                            <div class="top">
                                <div class="name">
                                    {{ item.GroupName }}
                                </div>
                                <div class="available-icon">Available</div>
                            </div>
                            <div class="overlay">
                                {{ item.TotalAvailableMachines }}<br>
                            </div>
                            <div class="small">Total Slots {{ item.TotalMachines }}</div>
                            <div class="total" :style="[{ backgroundColor: item.Color + '80', borderTop: '3px solid' + item.Color}]">
                                <img class="line" src="@/assets/images/backgrounds/line.png" alt="" style="width: 100%;">
                                <div class="text">
                                    <div class="left">
                                        <b>Min Bet</b><br>
                                        {{ item.MinBet | moneyFormat }}
                                    </div>
                                    <div class="right">
                                        <b>Max Bet</b><br>
                                        {{ item.MaxBet | moneyFormat }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <!-- Title -->
            <div class="title" data-aos="fade-in" v-if="liveGames">
                <!-- <div class="bar bar-left"></div> -->
                <div class="name">
                    LIVE GAMES
                </div>
                <!-- <div class="bar bar-right"></div> -->
            </div>
            <div class="row manufacturers  justify-content-center" v-if="liveGames">
                <div class="col-xxl-4 col-xl-3 col-lg-4 col-sm-4 col-12" v-for="item in liveGames" :key="item.id">
                    <a class="no-link" :class="{'disabled': !item.opened }" @click="createLiveGameSession(item)">
                        <div class="manufacturer" :style="[{ border: '3px solid #d19d6f'}]">
                            <div class="top">
                                <div class="name">
                                    {{ item.gameName }}
                                </div>
                                <div class="available-icon">{{ item.gameTypeName }}</div>
                            </div>
                            <div class="overlay" style="font-size: 60px; color: #3e8842;" v-if="item.opened">
                                OPEN
                            </div>
                            <div class="overlay" style="font-size: 60px; color: #f56039;" v-else>
                                CLOSED
                            </div>
                            <div class="total" :style="[{ backgroundColor: '#d19d6f80', borderTop: '3px solid #d19d6f'}]">
                                <img class="line" src="@/assets/images/backgrounds/line.png" alt="" style="width: 100%;">
                                <div class="text">
                                    <div class="left">
                                        <b>Min Bet</b><br>
                                        {{ item.limitMin | moneyFormat(true) }}
                                    </div>
                                    <div class="right">
                                        <b>Max Bet</b><br>
                                        {{ item.limitMax | moneyFormat(true) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <!-- Title -->
            <div class="title" data-aos="fade-in" v-if="showSideSessions">
                <!-- <div class="bar bar-left" style="max-width: 520px;"></div> -->
                <div class="name">
                    <img src="@/assets/images/slot.png" alt=""> <span style="color: #F23621;">LIVE</span> BackBetting Shows <img src="@/assets/images/slot.png" alt="">
                </div>
                <!-- <div class="bar bar-right" style="max-width: 520px;"></div> -->
            </div>
            <div class="row sidebet" data-aos="fade-in" v-if="sideSessions && showSideSessions">
                <div class="col-xl-4 col-lg-6 col-sm-12 col-12" v-for="item in sideSessions" :key="item.id" data-aos="fade-down">
                    <div class="session" :class="item.Status" @click="createSideSession(item)">
                        <div class="head">
                            <div class="bg">
                                <img class="w-100" src="@/assets/images/sidebet_bg.png" alt="">
                            </div>
                            <div class="players">
                                {{ item.LivePlayersCount }} PLAYERS LIVE
                            </div>
                            <div class="sessions">
                                {{ item.TotalPlayerSessions }} Sessions
                            </div>
                            <div class="join">
                                <div class="text" v-if="item.Status == 'RUNNING'">
                                    JOIN NOW!
                                </div>
                                <div class="text" v-if="item.Status == 'PAUSED'">
                                    PAUSED
                                </div>
                            </div>
                        </div>
                        <div class="dealer">
                            <div class="image">
                                <img :src="'/api/v1/unigens/download_picture?path=REVOLUTION_MEDIA&fileName=' + item.Player.LogoPictureFileName" alt="" width="100%">
                            </div>
                            <div class="details">
                                <div class="name">{{ item.Player.FullName }}</div>
                                <div class="timestamp">Started at {{ item.StartTime | moment("HH:mm on DD MMM") }}</div>
                                <div class="tags">
                                    <div class="tag" v-for="manufacturer in item.Manufacturers" :style="{'background-color': manufacturer.color }">
                                        {{ manufacturer.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bets">
                            <div class="bet player-limits">
                                <div class="min">
                                    <div class="text">Minimum Bet</div>
                                    <div class="value">{{ item.MinBetValue }}</div>
                                </div>
                                <div class="label">
                                    Player<br>
                                    Limits
                                </div>
                                <div class="max">
                                    <div class="text">Maximum Bet</div>
                                    <div class="value">{{ item.MaxBetValue | moneyFormat }}</div>
                                </div>
                            </div>
                            <!-- <div class="bet main">
                                <div class="min">
                                    <div class="text">Minimum Bet</div>
                                    <div class="value">{{ item.MinBetPercentOfMainBet }}%</div>
                                </div>
                                <div class="label">
                                    Main<br>
                                    Bet
                                </div>
                                <div class="max">
                                    <div class="text">Maximum Bet</div>
                                    <div class="value">{{ item.MaxBetPercentOfMainBet }}%</div>
                                </div>
                            </div> -->
                            <div class="bet bsession">
                                <div class="min">
                                    <div class="text">Finishing at</div>
                                    <div class="value" v-if="item.EndSessionLogicType == 'TIME'">{{ item.EndSessionLogicTimeValue | moment("HH:mm") }}</div>
                                </div>
                                <div class="label">
                                    Session<br>
                                    Info
                                </div>
                                <div class="max">
                                    <div class="text">Players</div>
                                    <div class="value">{{ item.LivePlayersCount }}/{{ item.MaxPlayers }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="pool">
                            <div class="bg">
                                <img src="@/assets/images/rank_bg.png" alt="" class="w-100">
                            </div>
                            <div class="text">Ranking Pool: {{ item.RankingPoolPercent }}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: "ManufacturersView",
    data() {
        return {
            data: null,
            sideSessions: null,
            user: null,
            showSideSessions: false,
            liveGames: null,
        }
    },
    async mounted() {

        await this.getUser();


        this.getManufactures();
        this.getSideSessions();

        if (process.env.VUE_APP_LOBBY_ENABLED == 'true') {
            this.getLiveGames();
        }

        let specialUsers = ['savoy_demo_1', 'savoy_demo_2', 'casino_demo'];
        if (specialUsers.includes(this.user.userName)) {
            this.showSideSessions = false;
        }


    },
    sockets: {
        update_broadcasters: function() {
            this.getManufactures();
        },
    },
    methods: {
        async createLiveGameSession(game) {
            try {
                let response = await axios.post('religa/sessions', game);
                let session = response.data;
                this.$router.push(`/live-games?userKey=${session.userKey}&tableId=${game.gameId}&gameType=${game.gameType}`)
            } catch (error) {
                console.log(error);
                this.$toasted.error('Server error');
            }
            //:to="'/live-games?gameId=' + item.gameId" 
        },
        async getLiveGames() {
            try {
                let response = await this.$http.get('religa/games');
                this.liveGames = response.data;
            } catch (error) {
                console.log(error);
                //this.$toasted.error('Server error');
            }
        },
        async getUser() {
            try {
                let response = await this.$http.get('application/userDetails');
                this.user = response.data;
            } catch (error) {
                console.log(error);
                //this.$toasted.error('Server error');
            }
        },
        async createSideSession(mainSession) {
            console.log('creating', mainSession);
            // Get current user
            let response = await this.$http.get('application/userDetails');
            let user = response.data;
            // Create the session and redirect
            let response2 = await this.$http.get(`unigens/create_player_side_session?sideSessionGuid=${mainSession.Guid}&playerId=${user.playerId}&sideGamePercent=0&playerSocketId=${this.$socket.id}`);
            if (response2.data.Status == "OK") {
                let sideSessionId = response2.data.Result[0].Guid;
                this.$router.push('/platform/layout/' + sideSessionId + '?device=Desktop&system=SidePlayer');
            } else {
                console.log('api error', response2.data);
                this.$toasted.error(response2.data.Result.ErrorCode);
            }
        },
        async getSideSessions() {
            let response = await this.$http.get('unigens/get_side_sessions?filterStatus=LIVE');
            this.sideSessions = response.data.Result;
        },
        async getManufactures() {
            let response = await this.$http.get('unigens/get_machine_groups');
            this.data = response.data.Result;
        },
    }
}
</script>